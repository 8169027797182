<template>
  <ProfileEdit />
</template>

<script>
import ProfileEdit from '@/components/views/VProfileEdit'
export default {
  components: {
    ProfileEdit
  }
}
</script>
